import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Heading, Stack, useToast } from "@chakra-ui/react";
import Nav from "./components/Nav";
import ProgressBar from "./components/PregressBar";
import GoBack from "./components/GoBack";
import {
  CustomDropDown,
  CustomInput,
  CustomTextarea,
} from "./components/CustomInputs";
import { useHistory } from "react-router-dom";
import FormButton from "./components/FormButton";
import ArrowRight from "../../assets/arrow-right.svg";
import axios from "axios";
import { updateStore } from "../../utils/littleStore/action";
import { useStateMachine } from "little-state-machine";

const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s";
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com";
const UNIVERSAL_API = "https://www.universal-tutorial.com";

const PersonalInformation = () => {
  const {
    action,
    state: {
      littleStore: { first_name, last_name, phone, country, city, join_reason },
    },
  } = useStateMachine(updateStore);

  let toast = useToast();
  let history = useHistory();
  const [countries, setCountries] = useState([]);

  const handleSubmit = () => {
    if (
      !first_name ||
      !last_name ||
      !phone ||
      !country ||
      !city ||
      !join_reason
    ) {
      return toast({
        title: "Complete the form",
        description: "Please complete the form to continue",
        status: "error",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
    }
    history.push("/Signup/ReferenceInformation");
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  // const fetchCountriesList = () => {
  //   const config = {
  //     headers: {
  //       Accept: "application/json",
  //       "api-token": API_TOKEN,
  //       "user-email": UNIVERSAL_API_EMAIL,
  //     },
  //   }
  //   axios
  //     .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
  //     .then(resp => {
  //       const authToken = resp.data.auth_token
  //       const authConfig = {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }

  //       axios
  //         .get(`${UNIVERSAL_API}/api/countries`, authConfig)
  //         .then(({ data }) => {
  //           let countryOptions = data.map(country => {
  //             let aCountry = { ...country, name: country.country_name }
  //             return aCountry
  //           })
  //           setCountries(countryOptions)
  //         })
  //         .catch(error => {
  //           console.dir(error.response)
  //         })
  //     })
  //     .catch(error => {
  //       console.error(error.response)
  //     })
  // }

  // useEffect(() => {
  //   // axios.get("https://restcountries.eu/rest/v2/all").then((response) => {
  //   //   setCountries(response.data);
  //   // });
  //   fetchCountriesList()
  // }, [])

  return (
    <Box paddingBottom={20}>
      <Box
        paddingY={5}
        position={["relative", "sticky"]}
        top={0}
        width="100%"
        backgroundColor="#F3F7FF"
        border="1px solid rgba(103, 121, 142, 0.1)"
        zIndex={2}
      >
        <Nav showLoginButton={true} />
        <Flex
          maxWidth="400px"
          marginX="auto"
          width="90%"
          position="relative"
          top={["0", "-30px"]}
          flexDirection="column"
          alignItems="center"
          paddingTop={[10, 5, 0]}
        >
          <ProgressBar progress={25} />
          <Heading
            fontFamily="Gordita"
            fontSize={24}
            fontWeight={700}
            color="#1D1E22"
            marginTop={10}
            marginBottom={3}
          >
            Personal Information
          </Heading>
          <Text
            fontFamily="CamptonLight"
            fontSize={16}
            fontWeight={500}
            color="#1D1E22"
            textAlign="center"
            lineHeight="25px"
          >
            The following are requirements for the general public to join the
            <b>Digital Epidemiology Network.</b>
          </Text>
        </Flex>
      </Box>
      {/* body */}
      <Flex
        flexDirection={["column", "column", "row"]}
        maxWidth="1037px"
        width="100%"
        marginX="auto"
        marginY={10}
      >
        <Box
          marginLeft={[5, 10, 18, 0]}
          width={["100%", "20%"]}
          marginBottom={10}
        >
          <GoBack />
        </Box>
        <Box maxW={443} width={["90%", "90%", "60%"]} marginX="auto">
          {/* NAMES */}
          <Stack spacing={[4, 8]}>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box width={["100%", "48%"]} paddingBottom={[4, 0]}>
                <CustomInput
                  required={true}
                  handleInput={(value) => action({ first_name: value })}
                  value={first_name}
                  width="100%"
                  label="First & Middle name"
                  inputType="text"
                  placeholder="First & Middle name"
                />
              </Box>
              <Box width={["100%", "48%"]}>
                <CustomInput
                  required={true}
                  handleInput={(value) => action({ last_name: value })}
                  value={last_name}
                  width="100%"
                  label="Last name"
                  placeholder="Last name"
                  inputType="text"
                />
              </Box>
            </Flex>
            <Box>
              <CustomInput
                required={true}
                handleInput={(value) => action({ phone: value })}
                value={phone}
                width="100%"
                label="Phone Number"
                placeholder="(+234)(81234567)"
                inputType="text"
              />
            </Box>
            <Box>
              <CustomDropDown
                required={true}
                options={countries}
                handleOption={(value) => {
                  action({ country: value.label });
                }}
                value={country}
                width="100%"
                label="Country"
                placeholder="Select Country"
                inputType="text"
              />
            </Box>
            <Box>
              <CustomInput
                required={true}
                handleInput={(value) => action({ city: value })}
                value={city}
                width="100%"
                label="City"
                placeholder="Enter Town / City"
                inputType="text"
              />
            </Box>

            <Box>
              <CustomTextarea
                required={true}
                handleInput={(value) => action({ join_reason: value })}
                value={join_reason}
                width="100%"
                label="Reasons for joining the network"
                placeholder="Why you want to join the network"
                inputType="text"
              />
            </Box>
          </Stack>
        </Box>
        <Box width="20%"></Box>
      </Flex>
      <Flex justifyContent="center" marginTop={18}>
        <FormButton
          handleButtonAction={handleSubmit}
          label="Continue"
          icon={ArrowRight}
        />
      </Flex>
    </Box>
  );
};

export default PersonalInformation;
